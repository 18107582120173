import GATSBY_COMPILED_MDX from "/Users/cliveharber/Projects/buspc/gatsby/content/parish_council/policies.mdx";
import React from 'react';
import {graphql, Link} from "gatsby";
import {MDXProvider} from "@mdx-js/react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import "../css/markdown-github.css";
import {StaticImage} from "gatsby-plugin-image";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExternalLinkAlt} from "@fortawesome/pro-solid-svg-icons";
const PageLink = ({name, url}) => React.createElement(Link, {
  to: url,
  className: "inline-block md:block mr-3 md:mx-0 py-2 mt-2 text-sm font-semibold text-gray-900 bg-transparent focus:outline-none",
  activeClassName: "border-b-2 border-indigo-600 font-black"
}, name);
const shortcodes = {
  StaticImage,
  FontAwesomeIcon,
  Link
};
const Page = file => {
  const {page, pages} = file.data;
  const title = page.frontmatter.title;
  const links = pages.edges;
  const image = page.frontmatter.featuredImage?.childImageSharp?.gatsbyImageData;
  return React.createElement(Layout, {
    headerClass: "relative bg-white"
  }, React.createElement(SEO, {
    title: title
  }), React.createElement("div", {
    className: "flex flex-col md:flex-row md:min-h-screen w-full"
  }, React.createElement("div", {
    className: "flex flex-col w-full md:w-64 text-gray-700 bg-white dark-mode:text-gray-200 dark-mode:bg-gray-800 flex-shrink-0 order-last md:order-none"
  }, React.createElement("div", {
    className: "flex-shrink-0 py-4 flex flex-row items-center justify-between md:pr-6 md:border-r-1 md:border-gray-200 md:fixed md:w-64 md:order-last"
  }, React.createElement("nav", {
    className: "flex-grow hidden md:block pb-4 md:pb-0 md:overflow-y-auto flex-wrap"
  }, links.map(({node}) => !node.frontmatter.title.startsWith('Freedom') && React.createElement(PageLink, {
    name: node.frontmatter.title,
    url: node.frontmatter.path,
    key: node.id
  })), (/community_group/).test(page.frontmatter.path) && React.createElement("a", {
    className: "block py-2 mt-2 text-sm font-semibold text-gray-900 bg-transparent focus:outline-none",
    href: 'http://www.e-clubhouse.org/sites/wintertonnl',
    target: '_blank',
    rel: 'noreferrer'
  }, " Winterton and District Lions Club ", React.createElement(FontAwesomeIcon, {
    size: 'xs',
    icon: faExternalLinkAlt
  })), (/parish_council/).test(page.frontmatter.path) && React.createElement("div", null, React.createElement("p", {
    className: "mt-8"
  }, "North Lincs Council links"), React.createElement("a", {
    href: "https://www.northlincs.gov.uk/your-council/have-your-say/members-register-of-interest/conduct-of-members/",
    className: "inline-block md:block mr-3 md:mx-0 py-2 mt-2 text-sm font-semibold text-gray-900 bg-transparent",
    target: "_blank",
    rel: "noreferrer"
  }, "Code of Conduct ", React.createElement(FontAwesomeIcon, {
    icon: faExternalLinkAlt,
    size: "xs"
  })), React.createElement("a", {
    href: "https://northlincolnshire.moderngov.co.uk/mgParishCouncilDetails.aspx?ID=287&LS=3",
    className: "inline-block md:block mr-3 md:mx-0 py-2 mt-2 text-sm font-semibold text-gray-900 bg-transparent",
    target: "_blank",
    rel: "noreferrer"
  }, "Register of Interests ", React.createElement(FontAwesomeIcon, {
    icon: faExternalLinkAlt,
    size: "xs"
  })))))), React.createElement("div", {
    className: "flex flex-col w-full md:pl-4"
  }, React.createElement("div", {
    className: "flex-shrink-0 py-0 flex flex-row mt-6"
  }, React.createElement("div", {
    className: "markdown-body w-full mb-6"
  }, React.createElement(MDXProvider, {
    components: shortcodes
  }, file.children))))));
};
Page
export default function GatsbyMDXWrapper(props) {
  return React.createElement(Page, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
export const query = graphql`
query ($id: String, $folder: String) {
  page: mdx(id: {eq: $id}) {
    frontmatter {
      path
      title
      featuredImage {
        childImageSharp {
          gatsbyImageData(width: 800, layout: CONSTRAINED)
        }
      }
    }
  }
  pages: allMdx(
    filter: {internal: {contentFilePath: {regex: $folder}}}
    sort: {fields: [frontmatter___weight], order: ASC}
  ) {
    edges {
      node {
        id
        frontmatter {
          title
          path
        }
      }
    }
  }
}
`;
