/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import Agenda21stJan2025 from '/static/agenda/2025/Agenda-21st-January-2025.pdf';
import Agenda17thDec2024 from '/static/agenda/2024/Agenda-17th-Dec-2024.pdf';
import Agenda21stMay2024 from '/static/agenda/2024/Agenda-21st-May-2024.pdf';
import Agenda18thJune2024 from '/static/agenda/2024/Agenda-18th-June-2024.pdf';
import Agenda16thJuly2024 from '/static/agenda/2024/Agenda-16th-July-2024.pdf';
import Agenda3rdSept2024 from '/static/agenda/2024/Agenda-3rd-Sept-2024.pdf';
import Agenda15thOct2024 from '/static/agenda/2024/Agenda-15th-Oct-2024.pdf';
import Agenda19thNov2024 from '/static/agenda/2024/Agenda-19th-Nov-2024.pdf';
import Agenda16thMay2023 from '/static/agenda/2023/Agenda-16th-May-2023.pdf';
import Agenda20thJune2023 from '/static/agenda/2023/Agenda-20th-June-2023.pdf';
import Agenda18thJuly2023 from '/static/agenda/2023/Agenda-18th-July-2023.pdf';
import Agenda5thSept2023 from '/static/agenda/2023/Agenda-5th-Sept-2023.pdf';
import Agenda17thOct2023 from '/static/agenda/2023/Agenda-17th-Oct-2023.pdf';
import Agenda31stOct2023EO from '/static/agenda/2023/Agenda-31st-Oct-2023-EO.pdf';
import Agenda21stNov2023 from '/static/agenda/2023/Agenda-21st-Nov-2023.pdf';
import Agenda19thDec2023 from '/static/agenda/2023/Agenda-19th-Dec-2023.pdf';
import Agenda16thJan2024 from '/static/agenda/2024/Agenda-16th-Jan-2024.pdf';
import Agenda20thFeb2024 from '/static/agenda/2024/Agenda-20th-Feb-2024.pdf';
import Agenda19thMarch2024 from '/static/agenda/2024/Agenda-19th-March-2024.pdf';
import Agenda16thApril2024 from '/static/agenda/2024/Agenda-16th-April-2024.pdf';
import APMAgenda from '/static/agenda/2024/APM-Agenda.pdf';
import Minutes from '/static/agenda/2024/Minutes.pdf';
import Agenda21stJune2022 from '/static/agenda/2022/Agenda-21st-June-2022.pdf';
import Agenda19thJuly2022 from '/static/agenda/2022/Agenda-19th-July-2022.pdf';
import Agenda6thSeptember2022 from '/static/agenda/2022/Agenda-6th-September-2022.pdf';
import Agenda18thOctober2022 from '/static/agenda/2022/Agenda-18th-October-2022.pdf';
import Agenda15thNovember2022 from '/static/agenda/2022/Agenda-15th-November-2022.pdf';
import Agenda20thDecember2022 from '/static/agenda/2022/Agenda-20th-December-2022.pdf';
import Agenda17thJanuary2023 from '/static/agenda/2023/Agenda-17th-January-2023.pdf';
import Agenda21stFebruary2023 from '/static/agenda/2023/Agenda-21st-February-2023.pdf';
import Agenda21stMarch2023 from '/static/agenda/2023/Agenda-21st-March-2023.pdf';
import Agenda18thApril2023 from '/static/agenda/2023/Agenda-18th-April-2023.pdf';
import Agenda from '/static/agenda/2023/Agenda.pdf';
import APMMinutes from '/static/agenda/2023/APM-Minutes.pdf';
import Agenda15thJune2021 from '/static/agenda/2021/Agenda-15th-June-2021.pdf';
import Agenda20thJuly2021website from '/static/agenda/2021/Agenda-20th-July-2021-website.pdf';
import Agenda7thSeptember2021 from '/static/agenda/2021/Agenda-7th-September-2021.pdf';
import Agenda21stSept2021EO from '/static/agenda/2021/Agenda-21st-Sept-2021-EO.pdf';
import Agenda19thOctober2021 from '/static/agenda/2021/Agenda-19th-October-2021.pdf';
import Agenda16thNovember2021 from '/static/agenda/2021/Agenda-16th-November-2021.pdf';
import Agenda21stDecember2021 from '/static/agenda/2021/Agenda-21st-December-2021.pdf';
import Agenda18thJanuary2022 from '/static/agenda/2022/Agenda18th-January-2022.pdf';
import Agenda15thFebruary2022 from '/static/agenda/2022/Agenda15th-February-2022.pdf';
import Agenda15thMarch2022 from '/static/agenda/2022/Agenda15th-March-2022.pdf';
import Agenda19thApril2022 from '/static/agenda/2022/Agenda-19th-April-2022.pdf';
import Agenda17thMay2022 from '/static/agenda/2022/Agenda-17th-May-2022.pdf';
import APM2022Minutes from '/static/agenda/2022/APM-2022-Minutes.pdf';
import Agenda8thJune2020headings from '/static/agenda/2020/Agenda-8th-June-2020-headings.pdf';
import Agenda13thJuly2020 from '/static/agenda/2020/Agenda-13th-July-2020.pdf';
import Agenda10thAugust2020 from '/static/agenda/2020/Agenda-10th-August-2020.pdf';
import Agenda8thSeptember2020bookmark from '/static/agenda/2020/Agenda-8th-September-2020-bookmark.pdf';
import Agenda12thOctober2020 from '/static/agenda/2020/Agenda-12th-October-2020.pdf';
import Agenda9thNovember2020 from '/static/agenda/2020/Agenda-9th-November-2020.pdf';
import Agenda14thDecember2020website from '/static/agenda/2020/Agenda-14th-December-2020-website.pdf';
import Agenda11thJanuary2021website from '/static/agenda/2021/Agenda-11th-January-2021-website.pdf';
import Agenda8thFebruary2021website from '/static/agenda/2021/Agenda-8th-February-2021-website.pdf';
import Agenda8thMarch2021website from '/static/agenda/2021/Agenda-8th-March-2021-website.pdf';
import Agenda12thApril2021website from '/static/agenda/2021/Agenda-12th-April-2021-website.pdf';
import Agenda4thMay2021website from '/static/agenda/2021/Agenda-4th-May-2021-website.pdf';
import Agendapublic from '/static/agenda/2021/Agenda-public.pdf';
function _createMdxContent(props) {
  const _components = Object.assign({
    h3: "h3",
    p: "p",
    br: "br"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h3, null, "Full Council Meeting Dates 2024/2025"), "\n", React.createElement("table", {
    role: "presentation"
  }, React.createElement("thead", null, React.createElement("tr", null, React.createElement("th", null, "Month"), "\n", React.createElement("th", null, "Date of Meeting"), "\n", React.createElement("th", null, "Agenda"))), React.createElement("tbody", null, React.createElement("tr", null, React.createElement("td", null, "May"), "\n", React.createElement("td", null, "21th"), React.createElement("td", null, React.createElement("a", {
    href: Agenda21stMay2024,
    target: "_blank",
    rel: "noreferrer noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : Agenda - 21st May 2024"))), React.createElement("tr", null, React.createElement("td", null, "June"), "\n", React.createElement("td", null, "18th"), React.createElement("td", null, React.createElement("a", {
    href: Agenda18thJune2024,
    target: "_blank",
    rel: "noreferrer noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : Agenda - 18th June 2024"))), React.createElement("tr", null, React.createElement("td", null, "July"), "\n", React.createElement("td", null, "16th"), React.createElement("td", null, React.createElement("a", {
    href: Agenda16thJuly2024,
    target: "_blank",
    rel: "noreferrer noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : Agenda - 16th July 2024"))), React.createElement("tr", null, React.createElement("td", null, "August"), "\n", React.createElement("td", null, "-"), "\n", React.createElement("td", null, "No Meeting")), React.createElement("tr", null, React.createElement("td", null, "September"), "\n", React.createElement("td", null, "3rd"), React.createElement("td", null, React.createElement("a", {
    href: Agenda3rdSept2024,
    target: "_blank",
    rel: "noreferrer noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : Agenda - 3rd September 2024"))), React.createElement("tr", null, React.createElement("td", null, "October"), "\n", React.createElement("td", null, "15th"), React.createElement("td", null, React.createElement("a", {
    href: Agenda15thOct2024,
    target: "_blank",
    rel: "noreferrer noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : Agenda - 15th October 2024"))), React.createElement("tr", null, React.createElement("td", null, "November"), "\n", React.createElement("td", null, "19th"), React.createElement("td", null, React.createElement("a", {
    href: Agenda19thNov2024,
    target: "_blank",
    rel: "noreferrer noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : Agenda - 19th November 2024"))), React.createElement("tr", null, React.createElement("td", null, "December"), "\n", React.createElement("td", null, "17th"), React.createElement("td", null, React.createElement("a", {
    href: Agenda17thDec2024,
    target: "_blank",
    rel: "noreferrer noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : Agenda - 17th December 2024"))), React.createElement("tr", null, React.createElement("td", null, "January"), "\n", React.createElement("td", null, "21st"), React.createElement("td", null, React.createElement("a", {
    href: Agenda21stJan2025,
    target: "_blank",
    rel: "noreferrer noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : Agenda - 21st January 2025 p"))), React.createElement("tr", null, React.createElement("td", null, "February"), "\n", React.createElement("td", null, "18th"), React.createElement("td")), React.createElement("tr", null, React.createElement("td", null, "March"), "\n", React.createElement("td", null, "18th"), React.createElement("td")), React.createElement("tr", null, React.createElement("td", null, "April"), "\n", React.createElement("td", null, "15th"), React.createElement("td")), React.createElement("tr", null, React.createElement("td", null, "May"), "\n", React.createElement("td", null, "20th"), React.createElement("td")))), "\n", React.createElement(_components.p, null, "All meetings will be held in the Village Hall commencing at 7.00pm unless otherwise stated."), "\n", React.createElement(_components.p, null, "The Annual Parish Meeting is provisionally booked for Tuesday 6th May 2025 at 6pm."), "\n", React.createElement(_components.h3, null, "Full Council Meeting Dates 2023/2024"), "\n", React.createElement("table", {
    role: "presentation"
  }, React.createElement("thead", null, React.createElement("tr", null, React.createElement("th", null, "Month"), "\n", React.createElement("th", null, "Date of Meeting"), "\n", React.createElement("th", null, "Agenda"))), React.createElement("tbody", null, React.createElement("tr", null, React.createElement("td", null, "May"), "\n", React.createElement("td", null, "16th"), React.createElement("td", null, React.createElement("a", {
    href: Agenda16thMay2023,
    target: "_blank",
    rel: "noreferrer noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : Agenda - 16th May 2023"))), React.createElement("tr", null, React.createElement("td", null, "June"), "\n", React.createElement("td", null, "20th"), React.createElement("td", null, React.createElement("a", {
    href: Agenda20thJune2023,
    target: "_blank",
    rel: "noreferrer noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : Agenda - 20th June 2023"))), React.createElement("tr", null, React.createElement("td", null, "July"), "\n", React.createElement("td", null, "18th"), React.createElement("td", null, React.createElement("a", {
    href: Agenda18thJuly2023,
    target: "_blank",
    rel: "noreferrer noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : Agenda - 18th July 2023"))), React.createElement("tr", null, React.createElement("td", null, "August"), "\n", React.createElement("td", null, "-"), "\n", React.createElement("td", null, "No Meeting")), React.createElement("tr", null, React.createElement("td", null, "September"), "\n", React.createElement("td", null, "5th"), React.createElement("td", null, React.createElement("a", {
    href: Agenda5thSept2023,
    target: "_blank",
    rel: "noreferrer noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : Agenda - 5th September 2023"))), React.createElement("tr", null, React.createElement("td", null, "October"), "\n", React.createElement("td", null, "17th"), React.createElement("td", null, React.createElement("a", {
    href: Agenda17thOct2023,
    target: "_blank",
    rel: "noreferrer noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : Agenda - 17th October 2023"))), React.createElement("tr", null, React.createElement("td", null, "October"), "\n", React.createElement("td", null, "17th"), React.createElement("td", null, React.createElement("a", {
    href: Agenda31stOct2023EO,
    target: "_blank",
    rel: "noreferrer noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : Agenda - 31st October 2023 - Extraordinary Meeting"))), React.createElement("tr", null, React.createElement("td", null, "November"), "\n", React.createElement("td", null, "21st"), React.createElement("td", null, React.createElement("a", {
    href: Agenda21stNov2023,
    target: "_blank",
    rel: "noreferrer noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : Agenda - 21st November 2023"))), React.createElement("tr", null, React.createElement("td", null, "December"), "\n", React.createElement("td", null, "19th"), React.createElement("td", null, React.createElement("a", {
    href: Agenda19thDec2023,
    target: "_blank",
    rel: "noreferrer noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : Agenda - 19th December 2023"))), React.createElement("tr", null, React.createElement("td", null, "January"), "\n", React.createElement("td", null, "16th"), React.createElement("td", null, React.createElement("a", {
    href: Agenda16thJan2024,
    target: "_blank",
    rel: "noreferrer noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : Agenda - 16th January 2024"))), React.createElement("tr", null, React.createElement("td", null, "February"), "\n", React.createElement("td", null, "20th"), React.createElement("td", null, React.createElement("a", {
    href: Agenda20thFeb2024,
    target: "_blank",
    rel: "noreferrer noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : Agenda - 20th February 2024"))), React.createElement("tr", null, React.createElement("td", null, "March"), "\n", React.createElement("td", null, "19st"), React.createElement("td", null, React.createElement("a", {
    href: Agenda19thMarch2024,
    target: "_blank",
    rel: "noreferrer noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : Agenda - 19th March 2024"))), React.createElement("tr", null, React.createElement("td", null, "April"), "\n", React.createElement("td", null, "16th"), React.createElement("td", null, React.createElement("a", {
    href: Agenda16thApril2024,
    target: "_blank",
    rel: "noreferrer noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : Agenda - 16th April 2024"))))), "\n", React.createElement(_components.p, null, "Meetings will be held in the Village Hall at 7:00 pm."), "\n", React.createElement(_components.p, null, "The Annual Parish Meeting was held on Tuesday 7", React.createElement("sup", null, "th"), " May 2024 at 6pm"), "\n", React.createElement(_components.p, null, React.createElement("a", {
    href: APMAgenda,
    target: "_blank",
    rel: "noreferrer noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : Annual Parish Meeting Agenda - 7th May 2024"), "\n", React.createElement(_components.br), "\n", React.createElement("a", {
    href: Minutes,
    target: "_blank",
    rel: "noreferrer noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : Annual Parish Meeting Minutes - 7th May 2024")), "\n", React.createElement(_components.h3, null, "Full Council Meeting Dates 2022/2023"), "\n", React.createElement("table", {
    role: "presentation"
  }, React.createElement("thead", null, React.createElement("tr", null, React.createElement("th", null, "Month"), "\n", React.createElement("th", null, "Date of Meeting"), "\n", React.createElement("th", null, "Agenda"))), React.createElement("tbody", null, React.createElement("tr", null, React.createElement("td", null, "June"), "\n", React.createElement("td", null, "21st"), React.createElement("td", null, React.createElement("a", {
    href: Agenda21stJune2022,
    target: "_blank",
    rel: "noreferrer noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : Agenda - 21th June 2022"))), React.createElement("tr", null, React.createElement("td", null, "July"), "\n", React.createElement("td", null, "19th"), React.createElement("td", null, React.createElement("a", {
    href: Agenda19thJuly2022,
    target: "_blank",
    rel: "noreferrer noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : Agenda - 19th July 2022"))), React.createElement("tr", null, React.createElement("td", null, "August"), "\n", React.createElement("td", null, "-"), "\n", React.createElement("td", null, "No Meeting")), React.createElement("tr", null, React.createElement("td", null, "September"), "\n", React.createElement("td", null, "6th"), React.createElement("td", null, React.createElement("a", {
    href: Agenda6thSeptember2022,
    target: "_blank",
    rel: "noreferrer noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : Agenda - 6th September 2022"))), React.createElement("tr", null, React.createElement("td", null, "October"), "\n", React.createElement("td", null, "18th"), React.createElement("td", null, React.createElement("a", {
    href: Agenda18thOctober2022,
    target: "_blank",
    rel: "noreferrer noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : Agenda - 18th October 2022"))), React.createElement("tr", null, React.createElement("td", null, "November"), "\n", React.createElement("td", null, "15th"), React.createElement("td", null, React.createElement("a", {
    href: Agenda15thNovember2022,
    target: "_blank",
    rel: "noreferrer noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : Agenda - 15th November 2022"))), React.createElement("tr", null, React.createElement("td", null, "December"), "\n", React.createElement("td", null, "20th"), React.createElement("td", null, React.createElement("a", {
    href: Agenda20thDecember2022,
    target: "_blank",
    rel: "noreferrer noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : Agenda - 20th December 2022"))), React.createElement("tr", null, React.createElement("td", null, "January"), "\n", React.createElement("td", null, "17th"), React.createElement("td", null, React.createElement("a", {
    href: Agenda17thJanuary2023,
    target: "_blank",
    rel: "noreferrer noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : Agenda - 17th January 2023"))), React.createElement("tr", null, React.createElement("td", null, "February"), "\n", React.createElement("td", null, "21st"), React.createElement("td", null, React.createElement("a", {
    href: Agenda21stFebruary2023,
    target: "_blank",
    rel: "noreferrer noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : Agenda - 21st February 2023"))), React.createElement("tr", null, React.createElement("td", null, "March"), "\n", React.createElement("td", null, "21st"), React.createElement("td", null, React.createElement("a", {
    href: Agenda21stMarch2023,
    target: "_blank",
    rel: "noreferrer noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : Agenda - 21st March 2023"))), React.createElement("tr", null, React.createElement("td", null, "April"), "\n", React.createElement("td", null, "18th"), React.createElement("td", null, React.createElement("a", {
    href: Agenda18thApril2023,
    target: "_blank",
    rel: "noreferrer noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : Agenda - 18th April 2023"))))), "\n", React.createElement(_components.p, null, "The Annual Parish Meeting was held on Tuesday 23", React.createElement("sup", null, "rd"), " May 2023."), "\n", React.createElement(_components.p, null, React.createElement("a", {
    rel: "noreferrer noopener",
    href: Agenda,
    target: "_blank"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : Annual Parish Meeting Agenda - 23th May 2023"), "\n", React.createElement(_components.br), "\n", React.createElement("a", {
    rel: "noreferrer noopener",
    href: APMMinutes,
    target: "_blank"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : Annual Parish Meeting Minutes - 23th May 2023")), "\n", React.createElement(_components.h3, null, "Council Meeting Dates 2021/2022"), "\n", React.createElement("table", {
    role: "presentation"
  }, React.createElement("thead", null, React.createElement("tr", null, React.createElement("th", null, "Month"), "\n", React.createElement("th", null, "Date of Meeting"), "\n", React.createElement("th", null, "Agenda"))), React.createElement("tbody", null, React.createElement("tr", null, React.createElement("td", null, "June"), "\n", React.createElement("td", null, "15th"), React.createElement("td", null, React.createElement("a", {
    href: Agenda15thJune2021,
    target: "_blank",
    rel: "noreferrer noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : Agenda - 15th June 2021"))), React.createElement("tr", null, React.createElement("td", null, "July"), "\n", React.createElement("td", null, "20th"), React.createElement("td", null, React.createElement("a", {
    href: Agenda20thJuly2021website,
    target: "_blank",
    rel: "noreferrer noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : Agenda - 20th July 2021"))), React.createElement("tr", null, React.createElement("td", null, "August"), "\n", React.createElement("td", null, "-"), "\n", React.createElement("td", null, "No Meeting")), React.createElement("tr", null, React.createElement("td", null, "September"), "\n", React.createElement("td", null, "7th"), React.createElement("td", null, React.createElement("a", {
    href: Agenda7thSeptember2021,
    target: "_blank",
    rel: "noreferrer noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : Agenda - 7th September 2021"))), React.createElement("tr", null, React.createElement("td", null, "September"), "\n", React.createElement("td", null, "21st"), React.createElement("td", null, React.createElement("a", {
    href: Agenda21stSept2021EO,
    target: "_blank",
    rel: "noreferrer noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : Agenda Extraordinary Meeting - 21st September 2021"))), React.createElement("tr", null, React.createElement("td", null, "October"), "\n", React.createElement("td", null, "19th"), React.createElement("td", null, React.createElement("a", {
    href: Agenda19thOctober2021,
    target: "_blank",
    rel: "noreferrer noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : Agenda - 19th October 2021"))), React.createElement("tr", null, React.createElement("td", null, "November"), "\n", React.createElement("td", null, "16th"), React.createElement("td", null, React.createElement("a", {
    href: Agenda16thNovember2021,
    target: "_blank",
    rel: "noreferrer noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : Agenda - 16th November 2021"))), React.createElement("tr", null, React.createElement("td", null, "December"), "\n", React.createElement("td", null, "21st"), React.createElement("td", null, React.createElement("a", {
    href: Agenda21stDecember2021,
    target: "_blank",
    rel: "noreferrer noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : Agenda - 21st December 2021"))), React.createElement("tr", null, React.createElement("td", null, "January"), "\n", React.createElement("td", null, "18th"), React.createElement("td", null, React.createElement("a", {
    href: Agenda18thJanuary2022,
    target: "_blank",
    rel: "noreferrer noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : Agenda - 18th January 2022"))), React.createElement("tr", null, React.createElement("td", null, "February"), "\n", React.createElement("td", null, "15th"), React.createElement("td", null, React.createElement("a", {
    href: Agenda15thFebruary2022,
    target: "_blank",
    rel: "noreferrer noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : Agenda - 15th February 2022"))), React.createElement("tr", null, React.createElement("td", null, "March"), "\n", React.createElement("td", null, "15th"), React.createElement("td", null, React.createElement("a", {
    href: Agenda15thMarch2022,
    target: "_blank",
    rel: "noreferrer noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : Agenda - 15th March 2022"))), React.createElement("tr", null, React.createElement("td", null, "April"), "\n", React.createElement("td", null, "19th"), React.createElement("td", null, React.createElement("a", {
    href: Agenda19thApril2022,
    target: "_blank",
    rel: "noreferrer noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : Agenda - 19th April 2022"))))), "\n", React.createElement(_components.p, null, "The Annual Parish meeting was held on Tuesday 17th May 2022."), "\n", React.createElement(_components.p, null, React.createElement("a", {
    rel: "noreferrer noopener",
    href: Agenda17thMay2022,
    target: "_blank"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : Annual Parish Meeting Agenda - 17th May 2021"), "\n", React.createElement(_components.br), "\n", React.createElement("a", {
    rel: "noreferrer noopener",
    href: APM2022Minutes,
    target: "_blank"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : Annual Parish Meeting Minutes - 17th May 2021")), "\n", React.createElement(_components.h3, null, "Council Meeting Dates 2020/2021"), "\n", React.createElement("table", {
    role: "presentation"
  }, React.createElement("thead", null, React.createElement("tr", null, React.createElement("th", null, "Month"), "\n", React.createElement("th", null, "Date of Meeting"), "\n", React.createElement("th", null, "Agenda"))), React.createElement("tbody", null, React.createElement("tr", null, React.createElement("td", null, "June"), "\n", React.createElement("td", null, "8th"), React.createElement("td", null, React.createElement("a", {
    href: Agenda8thJune2020headings,
    target: "_blank",
    rel: "noreferrer noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : Agenda - 8th June 2020"))), React.createElement("tr", null, React.createElement("td", null, "July"), "\n", React.createElement("td", null, "13th"), React.createElement("td", null, React.createElement("a", {
    href: Agenda13thJuly2020,
    target: "_blank",
    rel: "noreferrer noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : Agenda - 13th July 2020"))), React.createElement("tr", null, React.createElement("td", null, "August"), "\n", React.createElement("td", null, "10th"), React.createElement("td", null, React.createElement("a", {
    href: Agenda10thAugust2020,
    target: "_blank",
    rel: "noreferrer noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : Agenda - 10th August 2020"))), React.createElement("tr", null, React.createElement("td", null, "September"), "\n", React.createElement("td", null, "8th (Tues)"), React.createElement("td", null, React.createElement("a", {
    href: Agenda8thSeptember2020bookmark,
    target: "_blank",
    rel: "noreferrer noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : Agenda - 8th September 2020"))), React.createElement("tr", null, React.createElement("td", null, "October"), "\n", React.createElement("td", null, "13th (Tues)"), React.createElement("td", null, React.createElement("a", {
    href: Agenda12thOctober2020,
    target: "_blank",
    rel: "noreferrer noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : Agenda - 13th October 2020"))), React.createElement("tr", null, React.createElement("td", null, "November"), "\n", React.createElement("td", null, "9th"), React.createElement("td", null, React.createElement("a", {
    href: Agenda9thNovember2020,
    target: "_blank",
    rel: "noreferrer noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : Agenda - 9th November 2020"))), React.createElement("tr", null, React.createElement("td", null, "December"), "\n", React.createElement("td", null, "14th"), React.createElement("td", null, React.createElement("a", {
    rel: "noreferrer noopener",
    href: Agenda14thDecember2020website,
    target: "_blank"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : Agenda - 14th December 2020"))), React.createElement("tr", null, React.createElement("td", null, "January"), "\n", React.createElement("td", null, "11th"), React.createElement("td", null, React.createElement("a", {
    rel: "noreferrer noopener",
    href: Agenda11thJanuary2021website,
    target: "_blank"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : Agenda - 11th January 2020"))), React.createElement("tr", null, React.createElement("td", null, "February"), "\n", React.createElement("td", null, "8th"), React.createElement("td", null, React.createElement("a", {
    rel: "noreferrer noopener",
    href: Agenda8thFebruary2021website,
    target: "_blank"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : Agenda - 8th February 2020"))), React.createElement("tr", null, React.createElement("td", null, "March"), "\n", React.createElement("td", null, "8th"), React.createElement("td", null, React.createElement("a", {
    rel: "noreferrer noopener",
    href: Agenda8thMarch2021website,
    target: "_blank"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : Agenda - 8th March 2020"))), React.createElement("tr", null, React.createElement("td", null, "April"), "\n", React.createElement("td", null, "12th"), React.createElement("td", null, React.createElement("a", {
    rel: "noreferrer noopener",
    href: Agenda12thApril2021website,
    target: "_blank"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : Agenda - 12th April 2020"))), React.createElement("tr", null, React.createElement("td", null, "May"), "\n", React.createElement("td", null, "4th"), React.createElement("td", null, React.createElement("a", {
    rel: "noreferrer noopener",
    href: Agenda4thMay2021website,
    target: "_blank"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : Agenda - 4th May 2020"))))), "\n", React.createElement("a", {
    rel: "noreferrer noopener",
    href: Agendapublic,
    target: "_blank"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : Annual Parish Meeting Agenda - 18th May 2021"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
