/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    a: "a"
  }, _provideComponents(), props.components);
  return React.createElement(_components.p, null, "Information on noise and nuisance from the workings the wharf is being collected by a group of residents to pass onto the relevant organisations. If you have any information that you feel should be reported please pass it on using the app: ", React.createElement(_components.a, {
    href: "app.stoptthewharf.co.uk"
  }, "http://app.stoptthewharf.co.uk"), " which can be easily downloaded from Google.");
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
