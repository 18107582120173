import React from 'react'
import Layout from "../components/layout";
import {graphql, Link} from "gatsby";
import Card from '../components/link_card'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAt } from '@fortawesome/free-solid-svg-icons'
import {faExternalLinkAlt} from "@fortawesome/pro-regular-svg-icons";
import SEO from "../components/seo";

const ServiceLink = ({name, url}) => (
  <Link to={url}
        className="block py-2 mt-2 text-sm font-semibold text-gray-900 bg-transparent focus:outline-none"
        activeClassName={"border-b-2 border-indigo-600 font-black"}
  >{name}</Link>
)

const CommunityGroups = ({data}) => {
  const links = data.allMdx.edges;

  return (
    <Layout headerClass="bg-white relative">
      <SEO title="Community Groups" />
      <div className="flex flex-col md:flex-row md:min-h-screen w-full">
        <div className="flex flex-col w-full md:w-64 text-gray-700 bg-white dark-mode:text-gray-200 dark-mode:bg-gray-800 flex-shrink-0 order-last md:order-none">
          <div className="flex-shrink-0 py-4 flex flex-row items-center justify-between md:pr-6 md:border-r-1 md:border-gray-200 md:fixed md:w-64 md:order-last">
            <nav className="flex-grow md:block pb-4 md:pb-0 md:overflow-y-auto flex-wrap hidden md:block">
              {links.map(({ node }) => (
                <ServiceLink name={node.frontmatter.title} url={node.frontmatter.path} key={node.id} />
              ))}
              <a
                className="block py-2 mt-2 text-sm font-semibold text-gray-900 bg-transparent focus:outline-none"
                href={'http://www.e-clubhouse.org/sites/wintertonnl'}
                target={'_blank'}
                rel={'noreferrer'}
              >
                Winterton and District Lions Club <FontAwesomeIcon size={'xs'} icon={faExternalLinkAlt} />
              </a>
            </nav>
          </div>
        </div>
        <div className="flex flex-col w-full md:pl-4 mb-8">
          <div className="flex-shrink-0 py-0 flex flex-row mt-6">
            <div className="markdown-body">
              <h1>Community Groups</h1>
              <p>
                <span className="font-black">Burton upon Stather</span> with Normanby and Thealby is a thriving
                community with a number of active local not-for-profit clubs, groups and organisations.
              </p>
              <p>
                You can follow the link to find out more information on each particular group. To find out how to have
                your group listed for free, please contact the{' '}
                <a href={'mailto:clerk@burtonuponstatherparishcouncil.gov.uk'}>
                  <FontAwesomeIcon icon={faAt} size="sm" /> : clerk@burtonuponstatherparishcouncil.gov.uk
                </a>
                .
              </p>
            </div>
          </div>
          <hr className="mb-4 mt-6 border-gray-500" style={{ color: '#eee' }} />
          <div className="flex flex-wrap -mx-1 lg:-mx-4">
            {links.map(({ node }) => (
              <Card
                name={node.frontmatter.title}
                url={node.frontmatter.path}
                image={node.frontmatter.featuredImage?.childImageSharp?.gatsbyImageData}
                key={node.id}
                alt={node.frontmatter?.imageAltText}
              />
            ))}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`query {
  allMdx(
    filter: {internal: {contentFilePath: {regex: "/content/community_groups/"}}}
    sort: {fields: [frontmatter___weight], order: ASC}
  ) {
    edges {
      node {
        frontmatter {
          title
          path
          imageAltText
          featuredImage {
            childImageSharp {
              gatsbyImageData(width: 600, layout: CONSTRAINED)
            }
          }
        }
      }
    }
  }
}
`;

export default CommunityGroups
