import GATSBY_COMPILED_MDX from "/Users/cliveharber/Projects/buspc/gatsby/content/information/stop-the-wharf-campaign.mdx";
import React from "react";
import {graphql, Link} from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import {MDXProvider} from "@mdx-js/react";
const NoticeLink = ({title, date, author, slug}) => {
  return React.createElement(Link, {
    to: `/information/${slug}`,
    className: "my-1 w-9/12"
  }, React.createElement("article", {
    className: "overflow-hidden p-2 pr-4 bg-gray-400 rounded-l-lg text-right cursor-pointer"
  }, React.createElement("h4", {
    className: "text-lg text-black"
  }, title), React.createElement("p", {
    className: "text-xs text-gray-800"
  }, new Date(date).toLocaleString('en-GB', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  }), " by ", author)));
};
const Notice = ({data, children}) => {
  const {notice, notices: {edges: information}} = data;
  const {frontmatter: {title, date, author}} = notice;
  return React.createElement(Layout, {
    headerClass: "relative bg-white"
  }, React.createElement(SEO, {
    title: title
  }), React.createElement("div", {
    className: "flex flex-col md:flex-row mb-8 md:min-h-screen"
  }, React.createElement("div", {
    className: "w-full md:w-2/3 mr-8"
  }, React.createElement("header", {
    className: "w-full mb-6"
  }, React.createElement("div", {
    className: "py-0 mt-6"
  }, React.createElement("div", {
    className: "markdown-body"
  }, React.createElement("h1", null, title))), React.createElement("p", {
    className: "text-xs text-gray-700 mt-2 mb-4"
  }, new Date(date).toLocaleString('en-GB', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  }), " by ", author)), React.createElement("main", {
    className: "flex flex-col md:flex-row md:min-h-96 w-full"
  }, React.createElement(MDXProvider, null, children))), React.createElement("div", {
    className: "w-full md:w-1/3 bg-gray-700 pt-4 pr-2 rounded-r-lg md:rounded-r-none rounded-l-lg mt-8 md:mt-20"
  }, React.createElement("div", {
    className: "grid grid-cols-1 gap-4 justify-items-end"
  }, React.createElement("div", {
    className: "markdown-body w-11/12 text-right"
  }, React.createElement("h2", {
    className: "text-white"
  }, "Other Notices")), information && information.length === 0 && React.createElement("div", {
    className: " text-gray-400"
  }, "No other notices"), information && information.map(({node: {frontmatter: {title, date, author, path}}}) => React.createElement("div", null, React.createElement(NoticeLink, {
    title: title,
    date: date,
    author: author,
    slug: path,
    key: path
  }), React.createElement("div", {
    className: "text-right"
  }, React.createElement(Link, {
    className: "block py-2 mt-2 font-semibold text-gray-400 bg-transparent focus:outline-none",
    to: '/information'
  }, "View all ", React.createElement("i", {
    className: "fal fa-angle-double-right"
  })))))))));
};
Notice
export default function GatsbyMDXWrapper(props) {
  return React.createElement(Notice, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
export const query = graphql`
    query ($path: String) {
        notice: mdx(frontmatter: { path: { eq: $path } }) { 
            id
            frontmatter {
                author
                date
                title
                path
            }
        }
        notices: allMdx(
            filter: { frontmatter: { path: { regex: "/information/", ne: $path } } }
            sort: { fields: [frontmatter___date], order: DESC }
        ) {
            edges { node {
                id
                frontmatter {
                    author
                    date
                    title
                    path
                }
            }
            }
        }
    }
`;
