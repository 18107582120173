import React from 'react'
import Layout from "../components/layout";
import {graphql, Link} from "gatsby";
import {faBrowser} from "@fortawesome/pro-solid-svg-icons";
import {faExternalLinkAlt} from "@fortawesome/pro-regular-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
//import office from './parish_office.png'
import SEO from "../components/seo";

const ServiceLink = ({name, url}) => (
  <Link to={url}
        className="inline-block md:block mr-3 md:mx-0 md:py-2 mt-2 text-sm font-semibold text-gray-900 bg-transparent focus:outline-none"
        activeClassName={"border-b-2 border-indigo-600 font-black"}
  >{name}</Link>
)

const ParishCouncil = ({data}) => {
  const links = data.allMdx.edges
  return (
    <Layout headerClass="bg-white relative">
      <SEO title="Parish Council" />
      <div className="flex flex-col md:flex-row md:min-h-screen w-full">
        <div className="flex flex-col w-full md:w-64 text-gray-700 bg-white dark-mode:text-gray-200 dark-mode:bg-gray-800 flex-shrink-0 order-last md:order-none">
          <div className="flex-shrink-0 py-4 flex flex-row items-center justify-between md:pr-6 md:border-r-1 md:border-gray-200 md:fixed md:w-64 md:order-last">
            <nav className="flex-grow hidden md:block pb-4 md:pb-0 md:overflow-y-auto flex-wrap">
              <ul>
                {links.map(({ node }) => (
                  (!node.frontmatter.title.startsWith('Freedom') && <li key={node.id}>
                    <ServiceLink name={node.frontmatter.title} url={node.frontmatter.path} key={node.id} />
                  </li>)
                ))}
              </ul>
              <p className="mt-8">North Lincs Council links</p>
              <a
                href="https://www.northlincs.gov.uk/your-council/have-your-say/members-register-of-interest/conduct-of-members/"
                className="inline-block md:block mr-3 md:mx-0 py-2 mt-2 text-sm font-semibold text-gray-900 bg-transparent"
                target="_blank"
                rel="noreferrer"
              >
                Code of Conduct <FontAwesomeIcon icon={faExternalLinkAlt} size="xs" />
              </a>
              <a
                href="https://northlincolnshire.moderngov.co.uk/mgParishCouncilDetails.aspx?ID=287&LS=3"
                className="inline-block md:block mr-3 md:mx-0 py-2 mt-2 text-sm font-semibold text-gray-900 bg-transparent"
                target="_blank"
                rel="noreferrer"
              >
                Register of Interests <FontAwesomeIcon icon={faExternalLinkAlt} size="xs" />
              </a>
            </nav>
          </div>
        </div>
        <div className="flex flex-col w-full md:pl-4 office-image">
          <div className="mt-6">
            <div className="markdown-body">
              <h1>Parish Council</h1>
              <p>
                <span className="font-black">Burton upon Stather</span> Parish Council, which covers the villages of{' '}
                <span className="font-black">Burton upon Stather</span>, Normanby and Thealby, currently has 8 Councillors and
                meets on the third Tuesday of every month at the Village Hall. Members of the public are encouraged to
                attend meetings where they can either observe the discussions or address the Council in the fifteen
                minutes allocated at the beginning of each meeting. At the Annual Parish Council meeting held 14 May
                2012 councillors voted to adopt the North Lincolnshire Council Code of Conduct. All councillors are
                required to complete a Register of Interests form, which can be viewed on{' '}
                <a href={'https://northlincs.gov.uk'} target="_blank" rel="noreferrer">
                  <FontAwesomeIcon icon={faBrowser} size="sm" /> : northlincs.gov.uk{' '}
                  <FontAwesomeIcon icon={faExternalLinkAlt} size="xs" />
                </a>
              </p>
              <p className="mt-4 bg-white bg-opacity-50">
                The Parish Council is given powers and duties under the Local Government Act 1972 which impact directly
                on the community. <span className="font-black">Burton upon Stather</span> Parish Council maintains the
                Paddock, playing fields, Wiltshire Ave allotments, Thealby Play Area, the Parish Office and the Village
                Hall. It has also provided the brick built bus shelters, which are regularly cleaned by volunteers, dog
                and litter bins and the seats dedicated to the local fallen. Through devolution, it now cuts the grass
                verges on behalf of North Lincolnshire Council and is a consultee on planning applications in the
                parish.
              </p>
              <p className={'mt-4 bg-white bg-opacity-50'}>
                The Parish Council is always seeking for ways to improve the parish and welcomes contributions from
                members of the public. It is happy to represent the community to North Lincolnshire Council through
                working with the Ward Councillors.
              </p>
            </div>
          </div>
          {/*
          <hr className="mb-4 mt-6 border-gray-500" style={{color: '#eee'}}/>

          <div className="flex flex-wrap -mx-1 lg:-mx-4">
            {links.map(({node}) => (
                <Card name={node.title} url={node.slug} key={node.slug} />
              )
            )}
          </div>
          */}
        </div>
      </div>
    </Layout>
  )
}

export default ParishCouncil

export const query = graphql`
    query ParishCouncilQuery {
        allMdx(
            filter: { internal: { contentFilePath: { regex: "/content/parish_council/" } } }
            sort: {fields: [frontmatter___weight], order: ASC}
        ) {
            edges {
                node {
                    frontmatter {
                        title
                        path
                    }
                }
            }
        }
    }
`;
