import GATSBY_COMPILED_MDX from "/Users/cliveharber/Projects/buspc/gatsby/content/walks/brickponds.mdx";
import React from 'react';
import {graphql, Link} from "gatsby";
import {MDXProvider} from "@mdx-js/react";
import Layout from "../components/layout";
import SEO from "../components/seo";
import "../css/markdown-github.css";
import {GatsbyImage} from "gatsby-plugin-image";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
const PageLink = ({name, url}) => React.createElement(Link, {
  to: url,
  className: "inline-block md:block mr-3 md:mx-0 py-2 mt-2 text-sm font-semibold text-gray-900 bg-transparent focus:outline-none",
  activeClassName: "border-b-2 border-indigo-600 font-black"
}, name);
const shortcodes = {
  GatsbyImage,
  FontAwesomeIcon,
  Link
};
const Page = ({data, children}) => {
  const {pages} = data;
  const links = pages.edges;
  return React.createElement(Layout, {
    headerClass: "bg-white relative"
  }, React.createElement(SEO, {
    title: "Burton and Environ Walks"
  }), React.createElement("div", {
    className: "flex flex-col w-full"
  }, React.createElement("div", {
    className: "markdown-body mt-6"
  }, React.createElement("h1", null, "Exploring the Parish of Burton upon Stather, Normanby & Thealby")), React.createElement("div", {
    className: "markdown-body mb-0 mt-8 md:my-8"
  }, React.createElement("p", null, "Explore the local area by following one of these maps."))), React.createElement("div", {
    className: "md:flex flex-col md:flex-row md:min-h-screen w-full mb-8"
  }, React.createElement("div", {
    className: "flex flex-col w-full md:w-64 text-gray-700 bg-white dark-mode:text-gray-200 dark-mode:bg-gray-800 flex-shrink-0 order-last md:order-none"
  }, React.createElement("div", {
    className: "flex-shrink-0 py-4 flex flex-row items-center justify-between md:pr-6 md:border-r-1 md:border-gray-200 md:fixed md:w-64 md:order-last"
  }, React.createElement("nav", {
    className: "flex-grow hidden md:block pb-4 md:pb-0 md:overflow-y-auto flex-wrap"
  }, links.map(({node}) => React.createElement(PageLink, {
    name: node.frontmatter.title,
    url: node.frontmatter.path,
    key: node.id
  })), React.createElement("a", {
    href: "https://events.more-human.co.uk/group/1690368281079x733197106041962900",
    target: "_blank",
    rel: "noreferrer",
    className: "inline-block md:block mr-3 md:mx-0 py-2 mt-2 text-sm font-semibold text-gray-900 bg-transparent focus:outline-none"
  }, " Nature Trail"), React.createElement("a", {
    href: "https://www.northlincs.gov.uk/planning-and-environment/definitive-map/",
    target: "_blank",
    rel: "noreferrer",
    className: "inline-block md:block mr-3 md:mx-0 py-2 mt-2 text-sm font-semibold text-gray-900 bg-transparent focus:outline-none"
  }, " NLC - Definitive Map ")))), React.createElement("div", {
    className: "flex flex-col w-full md:pl-4"
  }, React.createElement("div", {
    className: "flex-shrink-0 py-0 flex flex-row md:mt-6"
  }, React.createElement("div", {
    className: "markdown-body w-full"
  }, React.createElement(MDXProvider, {
    components: shortcodes
  }, children))))));
};
Page
export default function GatsbyMDXWrapper(props) {
  return React.createElement(Page, props, React.createElement(GATSBY_COMPILED_MDX, props));
}
export const query = graphql`
query ($id: String, $folder: String) {
  page: mdx(id: {eq: $id}) {
    frontmatter {
      path
      title
      featuredImage {
        childImageSharp {
          gatsbyImageData(width: 800, layout: CONSTRAINED)
        }
      }
    }
  }
  pages: allMdx(
    filter: {internal: {contentFilePath: {regex: $folder}}}
    sort: {fields: [frontmatter___weight], order: ASC}
  ) {
    edges {
      node {
        id
        frontmatter {
          title
          path
        }
      }
    }
  }
}
`;
