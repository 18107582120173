/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import application_form from '/static/burial_ground/Application_to_Purchase_Right_of_Burial_of_Grave_june_2024.pdf';
import fees from '/static/burial_ground/Fees_and_Charges_v2.pdf';
import memorial from '/static/burial_ground/Memorial_Application_for_BURTON_june_2024.pdf';
import regConventional from '/static/burial_ground/Regulations_CONVENTIONAL_june_2024.pdf';
import regNatural from '/static/burial_ground/Regulations_NATURAL_BURIAL_june_2024.pdf';
import internment from '/static/burial_ground/Notice_of_Interment_june_2024.pdf';
import {faFilePdf, faAt, faPhoneSquareAlt} from "@fortawesome/pro-solid-svg-icons";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    h2: "h2",
    p: "p",
    a: "a",
    h3: "h3",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components), {FontAwesomeIcon} = _components;
  if (!FontAwesomeIcon) _missingMdxReference("FontAwesomeIcon", true);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, null, "Burton Burial Ground"), "\n", React.createElement("div", {
    className: "w-full md:w-2/5 md:pl-6 mb-4 md:mb-0 text-right p-4 z-40 md:ml-6 bg-gray-700 rounded-r-lg md:rounded-r-none rounded-l-lg text-white inverse float-right inline-block inset-0"
  }, React.createElement(_components.h2, null, "Contact details"), React.createElement(_components.p, null, "For further details please contact the Burial Clerk; Mrs Candace Brent."), React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "tel:+447982265914"
  }, React.createElement(FontAwesomeIcon, {
    icon: faPhoneSquareAlt,
    size: "sm"
  }), " : 07982 265914")), React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "mailto:clerk@burtonuponstatherparishcouncil.gov.uk"
  }, React.createElement(FontAwesomeIcon, {
    icon: faAt,
    size: "sm"
  }), " : clerk@burtonuponstatherparishcouncil.gov.uk")), React.createElement(_components.h3, null, "Forms & Information"), React.createElement("a", {
    href: application_form,
    target: "_blank",
    rel: "noopener"
  }, React.createElement(FontAwesomeIcon, {
    icon: faFilePdf,
    size: "sm"
  }), " : Application to Purchase Right of Burial of Grave"), React.createElement("br"), "\n", React.createElement("a", {
    href: memorial,
    target: "_blank",
    rel: "noopener"
  }, React.createElement(FontAwesomeIcon, {
    icon: faFilePdf,
    size: "sm"
  }), " : Memorial Application - Burton"), React.createElement("br"), "\n", React.createElement("a", {
    href: fees,
    target: "_blank",
    rel: "noopener"
  }, React.createElement(FontAwesomeIcon, {
    icon: faFilePdf,
    size: "sm"
  }), " : Fees & Charges"), React.createElement("br"), "\n", React.createElement("a", {
    href: regConventional,
    target: "_blank",
    rel: "noopener"
  }, React.createElement(FontAwesomeIcon, {
    icon: faFilePdf,
    size: "sm"
  }), " : Regulations - Conventional"), React.createElement("br"), "\n", React.createElement("a", {
    href: regNatural,
    target: "_blank",
    rel: "noopener"
  }, React.createElement(FontAwesomeIcon, {
    icon: faFilePdf,
    size: "sm"
  }), " : Regulations - Natural Burial"), React.createElement("br"), "\n", React.createElement("a", {
    href: internment,
    target: "_blank",
    rel: "noopener"
  }, React.createElement(FontAwesomeIcon, {
    icon: faFilePdf,
    size: "sm"
  }), " : Notice of Internment")), "\n", React.createElement(_components.h3, null, "A Place of beauty, peace and tranquility"), "\n", React.createElement(_components.p, null, "Our rural cemetery is set in the beautiful village of ", React.createElement("span", {
    className: "font-black"
  }, "Burton upon Stather"), " in quiet area that overlooks the rolling hills and valleys towards the River Humber."), "\n", React.createElement(_components.p, null, "It has been carefully designed to accommodate all religions and ethnic groups as well as a separate area for green burials. There is also an area allocated for cremated remains. A burial area for infants is included within these grounds."), "\n", React.createElement(_components.p, null, "The whole cemetery is digitally mapped to produce records for future generations."), "\n", React.createElement(_components.p, null, "St Andrews Church is an 11th century church which used to house all burials within the churchyard. The Church yard is nearly full which necessitated the creation of ", React.createElement("span", {
    className: "font-black"
  }, "Burton upon Stather"), " burial ground. The church is still available for services with committals in the new cemetery."), "\n", React.createElement(_components.p, null, "Our new burial ground has been designed to accommodate people of all religions and those of no religion."), "\n", React.createElement(_components.p, null, "Most funerals are arranged by a chosen funeral director and carried out under their guidance. However, if a family wishes to make their own arrangements we will be happy to discuss these. Graves can be individually blessed at the time of the burial."), "\n", React.createElement(_components.h3, null, "Natural Meadow Burial Ground"), "\n", React.createElement(_components.p, null, "The green burial area is a haven of peace and beauty where the meadow provides a lasting feature in the landscape. The concept is to create an area of wood land with selected trees planted within the hedgerows."), "\n", React.createElement(_components.p, null, "This will give the effect of an open meadow which will become a rich habitat for birds and other wildlife."), "\n", React.createElement(_components.p, null, "We request that burials within this natural setting take place in a biodegradable coffin, casket or shroud."), "\n", React.createElement(_components.h3, null, "Charges"), "\n", React.createElement("table", {
    className: "w-full"
  }, React.createElement("tbody", null, React.createElement("tr", null, React.createElement("th", {
    colSpan: "3"
  }, "Summary of Charges")), React.createElement("tr", null, React.createElement("td", null, " "), "\n", React.createElement("td", null, "Resident of Parish"), "\n", React.createElement("td", null, "Non-Resident of Parish")), React.createElement("tr", null, React.createElement("td", {
    colSpan: "3"
  }, React.createElement("b", null, "Conventional Area of the Cemetery"))), React.createElement("tr", null, React.createElement("td", null, "Single grave plot"), "\n", React.createElement("td", null, "£450.00"), "\n", React.createElement("td", null, "£550.00")), React.createElement("tr", null, React.createElement("td", null, "Pre-paid grave plot"), "\n", React.createElement("td", null, "£450.00"), "\n", React.createElement("td", null, "£550.00")), React.createElement("tr", null, React.createElement("td", null, "Internment fee"), "\n", React.createElement("td", {
    colSpan: "2"
  }, "charged via Funeral Director")), React.createElement("tr", null, React.createElement("td", {
    colSpan: "3"
  }, React.createElement("b", null, "Cremated Remains Area of the Cemetery"))), React.createElement("tr", null, React.createElement("td", null, "Ashes Plot"), "\n", React.createElement("td", null, "£250.00"), "\n", React.createElement("td", null, "£350.00")), React.createElement("tr", null, React.createElement("td", null, "Internment fee"), "\n", React.createElement("td", null, "£40.00"), "\n", React.createElement("td", null, "£40.00")), React.createElement("tr", null, React.createElement("td", {
    colSpan: "3"
  }, React.createElement("b", null, "Green Burial Area of the Cemetery"))), React.createElement("tr", null, React.createElement("td", null, "Single Grave Plot"), "\n", React.createElement("td", null, "£450.00"), "\n", React.createElement("td", null, "£550.00")), React.createElement("tr", null, React.createElement("td", null, "Pre-paid Grave Plot"), "\n", React.createElement("td", null, "£450.00"), "\n", React.createElement("td", null, "£550.00")), React.createElement("tr", null, React.createElement("td", null, "Single Ashes Plot"), "\n", React.createElement("td", null, "£250.00"), "\n", React.createElement("td", null, "£350.00")), React.createElement("tr", null, React.createElement("td", null, "Internment fee for burial"), "\n", React.createElement("td", {
    colSpan: "2"
  }, "charged via Funeral Director")), React.createElement("tr", null, React.createElement("td", null, "Internment fee for ashes"), "\n", React.createElement("td", null, "£40.00"), "\n", React.createElement("td", null, "£40.00")), React.createElement("tr", null, React.createElement("td", {
    colSpan: "3"
  }, React.createElement("b", null, "Right to Erect a Memorial"), React.createElement("br"), "(Conventional and Cremated Remains areas.)")), React.createElement("tr", null, React.createElement("td", null, "Up to 18\" (46cm) High"), "\n", React.createElement("td", {
    colSpan: "2"
  }, "£60.00")), React.createElement("tr", null, React.createElement("td", null, "18\" (46cm) to 36\" (92cm) High"), "\n", React.createElement("td", {
    colSpan: "2"
  }, "£120.00")), React.createElement("tr", null, React.createElement("td", null, "6\" x 12\" plaque in natural burial ground"), "\n", React.createElement("td", {
    colSpan: "2"
  }, "£20.00")), React.createElement("tr", null, React.createElement("td", null, "Additional Inscription"), "\n", React.createElement("td", {
    colSpan: "2"
  }, "£30.00")))), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Prices are reviewed on an annual basis."), "\n", React.createElement(_components.li, null, "All Payment to be made to ", React.createElement("span", {
    className: "font-black"
  }, "Burton upon Stather"), " Parish Council."), "\n", React.createElement(_components.li, null, "Full terms and conditions available on request."), "\n", React.createElement(_components.li, null, "Fees for other services are detailed on ", React.createElement("a", {
    href: fees,
    target: "_blank",
    rel: "noopener"
  }, React.createElement(FontAwesomeIcon, {
    icon: faFilePdf,
    size: "sm"
  }), " : Fees & Charges"), React.createElement("br")), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
function _missingMdxReference(id, component) {
  throw new Error("Expected " + (component ? "component" : "object") + " `" + id + "` to be defined: you likely forgot to import, pass, or provide it.");
}
