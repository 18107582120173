/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
import Dec2024 from '/static/minutes/2024/Dec-2024.pdf';
import Nov2024 from '/static/minutes/2024/Nov-2024.pdf';
import Oct2024 from '/static/minutes/2024/Oct-2024.pdf';
import Sept2024 from '/static/minutes/2024/Sept-2024.pdf';
import July2024 from '/static/minutes/2024/July-2024.pdf';
import June2024 from '/static/minutes/2024/June-2024.pdf';
import May2024 from '/static/minutes/2024/May-2024.pdf';
import April2024 from '/static/minutes/2024/April-2024.pdf';
import March2024 from '/static/minutes/2024/March-2024.pdf';
import Feb2024 from '/static/minutes/2024/Feb-2024.pdf';
import Jan2024 from '/static/minutes/2024/Jan-2024.pdf';
import MinutesDec23 from '/static/minutes/2023/Minutes-Dec-23.pdf';
import MinutesNov231 from '/static/minutes/2023/Minutes-Nov-23-1.pdf';
import MinutesOct23 from '/static/minutes/2023/Minutes-Oct-23.pdf';
import MinutesSept23 from '/static/minutes/2023/Minutes-Sept-23.pdf';
import MinutesJuly23 from '/static/minutes/2023/Minutes-July-23.pdf';
import MinutesJune23 from '/static/minutes/2023/Minutes-June-23.pdf';
import MinutesMay23 from '/static/minutes/2023/Minutes-May-23.pdf';
import MinutesApril23 from '/static/minutes/2023/Minutes-April-23.pdf';
import MinutesMarch23 from '/static/minutes/2023/Minutes-March-23.pdf';
import MinutesFeb23 from '/static/minutes/2023/Minutes-Feb-23.pdf';
import MinutesJan232 from '/static/minutes/2023/Minutes-Jan-23-2.pdf';
import MinutesDec2022 from '/static/minutes/2022/Minutes-Dec-2022.pdf';
import MinutesNov2022 from '/static/minutes/2022/Minutes-Nov-2022.pdf';
import MinutesOct2022 from '/static/minutes/2022/Minutes-Oct-2022.pdf';
import MinutesSept2022 from '/static/minutes/2022/Minutes-Sept-2022.pdf';
import MinutesJune2022 from '/static/minutes/2022/Minutes-June-2022.pdf';
import Minutes from '/static/minutes/2022/Minutes.pdf';
import MinutesMay2022 from '/static/minutes/2022/Minutes-May-2022.pdf';
import MinutesApril20221 from '/static/minutes/2022/Minutes-April-2022-1.pdf';
import MinutesMarch2022 from '/static/minutes/2022/Minutes-March-2022.pdf';
import MinutesFeb2022 from '/static/minutes/2022/Minutes-Feb-2022.pdf';
import MinutesJan2022 from '/static/minutes/2022/Minutes-Jan-2022.pdf';
import MinutesDec2021 from '/static/minutes/2021/Minutes-Dec-2021.pdf';
import MinutesNov2021 from '/static/minutes/2021/Minutes-Nov-2021.pdf';
import MinutesOct2021 from '/static/minutes/2021/Minutes-Oct-2021.pdf';
import MinutesSept2021EO from '/static/minutes/2021/Minutes-Sept-2021-EO.pdf';
import MinutesSept2021 from '/static/minutes/2021/Minutes-Sept-2021.pdf';
import MinutesJuly2021 from '/static/minutes/2021/Minutes-July-2021.pdf';
import MinutesJune2021 from '/static/minutes/2021/Minutes-June-2021.pdf';
import AnnualParishMinutes2021 from '/static/minutes/2021/Annual-Parish-Minutes-2021.pdf';
import MinutesMay2021 from '/static/minutes/2021/Minutes-May-2021.pdf';
import MinutesApril20211 from '/static/minutes/2021/Minutes-April-2021-1.pdf';
import MinutesMarch20211 from '/static/minutes/2021/Minutes-March-2021-1.pdf';
import MinutesFebruary2021 from '/static/minutes/2021/Minutes-February-2021.pdf';
import MinutesJanuary2021 from '/static/minutes/2021/Minutes-January-2021.pdf';
import MinutesDecember2020 from '/static/minutes/2020/Minutes-December-2020.pdf';
import MinutesNovember2020 from '/static/minutes/2020/Minutes-November-2020.pdf';
import MinutesOctober2020 from '/static/minutes/2020/Minutes-October-2020.pdf';
import MinutesSeptember2020 from '/static/minutes/2020/Minutes-September-2020.pdf';
import MinutesAugust2020 from '/static/minutes/2020/Minutes-August-2020.pdf';
import MinutesJuly2020 from '/static/minutes/2020/Minutes-July-2020.pdf';
import MinutesJune2020 from '/static/minutes/2020/Minutes-June-2020.pdf';
import MinutesEOmeeting010620 from '/static/minutes/2020/Minutes-EO-meeting-010620.pdf';
import MinutesMay20201 from '/static/minutes/2020/Minutes-May-2020-1.pdf';
import MinutesApril2020bookmarked from '/static/minutes/2020/Minutes-April-2020-bookmarked.pdf';
import MinutesMarch2020 from '/static/minutes/2020/Minutes-March-2020.pdf';
import MinutesEOmeeting280220 from '/static/minutes/2020/Minutes-EO-meeting-280220.pdf';
import MinutesFeb2020amended from '/static/minutes/2020/Minutes-Feb-2020-amended.pdf';
import MinutesJan2020 from '/static/minutes/2020/Minutes-Jan-2020.pdf';
import Minutes091219 from '/static/minutes/2019/Minutes-091219.pdf';
import Minutes111119 from '/static/minutes/2019/Minutes-111119.pdf';
import Minutes141019 from '/static/minutes/2019/Minutes-141019.pdf';
import Minutes240919EO from '/static/minutes/2019/Minutes-240919-EO.pdf';
import Minutes090919 from '/static/minutes/2019/Minutes-090919.pdf';
import Minutes120819 from '/static/minutes/2019/Minutes-120819.pdf';
import Minutes080719 from '/static/minutes/2019/Minutes-080719.pdf';
import Minutes100619draft from '/static/minutes/2019/Minutes-100619-draft.pdf';
import Minutes1305191 from '/static/minutes/2019/Minutes-130519-1.pdf';
import Minutes1 from '/static/minutes/2019/Minutes-1.pdf';
import Minutes080419 from '/static/minutes/2019/Minutes-080419.pdf';
import Minutes110319DRAFT from '/static/minutes/2019/Minutes-110319-DRAFT.pdf';
import Mins11February2019DRAFT from '/static/minutes/2019/Mins-11-February-2019-DRAFT.pdf';
import Mins14January2019 from '/static/minutes/2019/Mins-14-January-2019.pdf';
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    h2: "h2",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, null, "Minutes of Council Meetings"), "\n", React.createElement(_components.h2, null, "2024"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    rel: "noreferrer noopener",
    href: Dec2024,
    target: "_blank",
    "aria-label": "Opens PDF file"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : December (DRAFT) "), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    rel: "noreferrer noopener",
    href: Nov2024,
    target: "_blank",
    "aria-label": "Opens PDF file"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : November  "), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    rel: "noreferrer noopener",
    href: Oct2024,
    target: "_blank"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : October "), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    rel: "noreferrer noopener",
    href: Sept2024,
    target: "_blank"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : September  "), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    rel: "noreferrer noopener",
    href: July2024,
    target: "_blank"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : July  "), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    rel: "noreferrer noopener",
    href: June2024,
    target: "_blank"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : June "), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    rel: "noreferrer noopener",
    href: May2024,
    target: "_blank"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : May  "), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    rel: "noreferrer noopener",
    href: April2024,
    target: "_blank"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : April "), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    rel: "noreferrer noopener",
    href: March2024,
    target: "_blank"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : March "), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    rel: "noreferrer noopener",
    href: Feb2024,
    target: "_blank"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : February "), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    rel: "noreferrer noopener",
    href: Jan2024,
    target: "_blank"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : January "), "\n"), "\n"), "\n", React.createElement(_components.h2, null, "2023"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    rel: "noreferrer noopener",
    href: MinutesDec23,
    target: "_blank"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : December "), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    rel: "noreferrer noopener",
    href: MinutesNov231,
    target: "_blank"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : November  "), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    rel: "noreferrer noopener",
    href: MinutesOct23,
    target: "_blank"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : October "), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    rel: "noreferrer noopener",
    href: MinutesSept23,
    target: "_blank"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : September"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    rel: "noreferrer noopener",
    href: MinutesJuly23,
    target: "_blank"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : July"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    rel: "noreferrer noopener",
    href: MinutesJune23,
    target: "_blank"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : June "), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    rel: "noreferrer noopener",
    href: MinutesMay23,
    target: "_blank"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : May "), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    rel: "noreferrer noopener",
    href: MinutesApril23,
    target: "_blank"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : April"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    rel: "noreferrer noopener",
    href: MinutesMarch23,
    target: "_blank"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : March"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    rel: "noreferrer noopener",
    href: MinutesFeb23,
    target: "_blank"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : February "), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    rel: "noreferrer noopener",
    href: MinutesJan232,
    target: "_blank"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : January "), "\n"), "\n"), "\n", React.createElement(_components.h2, null, "2022"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    rel: "noreferrer noopener",
    href: MinutesDec2022,
    target: "_blank"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : December "), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    rel: "noreferrer noopener",
    href: MinutesNov2022,
    target: "_blank"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : November "), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    rel: "noreferrer noopener",
    href: MinutesOct2022,
    target: "_blank"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : October"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    rel: "noreferrer noopener",
    href: MinutesSept2022,
    target: "_blank"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : September"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    rel: "noreferrer noopener",
    href: MinutesJune2022,
    target: "_blank"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : June"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    rel: "noreferrer noopener",
    href: Minutes,
    target: "_blank"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : Annual Parish Meeting 24 May (DRAFT)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    rel: "noreferrer noopener",
    href: MinutesMay2022,
    target: "_blank"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : May"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    rel: "noreferrer noopener",
    href: MinutesApril20221,
    target: "_blank"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : April"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    rel: "noreferrer noopener",
    href: MinutesMarch2022,
    target: "_blank"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : March"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    rel: "noreferrer noopener",
    href: MinutesFeb2022,
    target: "_blank"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : February "), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    rel: "noreferrer noopener",
    href: MinutesJan2022,
    target: "_blank"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : January"), "\n"), "\n"), "\n", React.createElement(_components.h2, null, "2021"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    href: MinutesDec2021,
    target: "_blank",
    rel: "noreferrer noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : December"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    href: MinutesNov2021,
    target: "_blank",
    rel: "noreferrer noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : November"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    href: MinutesOct2021,
    target: "_blank",
    rel: "noreferrer noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : October"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    href: MinutesSept2021EO,
    target: "_blank",
    rel: "noreferrer noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : September (Extraordinary meeting)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    href: MinutesSept2021,
    target: "_blank",
    rel: "noreferrer noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : September"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    href: MinutesJuly2021,
    target: "_blank",
    rel: "noreferrer noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : July"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    href: MinutesJune2021,
    target: "_blank",
    rel: "noreferrer noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : June"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    href: AnnualParishMinutes2021,
    target: "_blank",
    rel: "noreferrer noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : Annual Parish Meeting 18 May"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    href: MinutesMay2021,
    target: "_blank",
    rel: "noreferrer noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : May"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    href: MinutesApril20211,
    target: "_blank",
    rel: "noreferrer noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : April"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    href: MinutesMarch20211,
    target: "_blank",
    rel: "noreferrer noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : March"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    href: MinutesFebruary2021,
    target: "_blank",
    rel: "noreferrer noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : February"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    href: MinutesJanuary2021,
    target: "_blank",
    rel: "noreferrer noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : January"), "\n"), "\n"), "\n", React.createElement(_components.h2, null, "2020"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    href: MinutesDecember2020,
    target: "_blank",
    rel: "noreferrer noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : December"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    href: MinutesNovember2020,
    target: "_blank",
    rel: "noreferrer noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : November"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    href: MinutesOctober2020,
    target: "_blank",
    rel: "noreferrer noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : October"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    href: MinutesSeptember2020,
    target: "_blank",
    rel: "noreferrer noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : September"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    href: MinutesAugust2020,
    target: "_blank",
    rel: "noreferrer noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : August"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    href: MinutesJuly2020,
    target: "_blank",
    rel: "noreferrer noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : July"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    href: MinutesJune2020,
    target: "_blank",
    rel: "noreferrer noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : June"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    href: MinutesEOmeeting010620,
    target: "_blank",
    rel: "noreferrer noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : June (Extraordinary meeting)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    href: MinutesMay20201,
    target: "_blank",
    rel: "noreferrer noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : May"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    href: MinutesApril2020bookmarked,
    target: "_blank",
    rel: "noreferrer noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : April"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    href: MinutesMarch2020,
    target: "_blank",
    rel: "noreferrer noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : March"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    href: MinutesEOmeeting280220,
    target: "_blank",
    rel: "noreferrer noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : February - (Extraordinary meeting)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    href: MinutesFeb2020amended,
    target: "_blank",
    rel: "noreferrer noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : February"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    href: MinutesJan2020,
    target: "_blank",
    rel: "noreferrer noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : January"), "\n"), "\n"), "\n", React.createElement(_components.h2, null, "2019"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    href: Minutes091219,
    target: "_blank",
    rel: "noreferrer noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : December"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    href: Minutes111119,
    target: "_blank",
    rel: "noreferrer noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : November"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    href: Minutes141019,
    target: "_blank",
    rel: "noreferrer noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : October"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    href: Minutes240919EO,
    target: "_blank",
    rel: "noreferrer noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : September - (Extraordinary meeting)"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    href: Minutes090919,
    target: "_blank",
    rel: "noreferrer noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : September"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    href: Minutes120819,
    target: "_blank",
    rel: "noreferrer noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : August"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    href: Minutes080719,
    target: "_blank",
    rel: "noreferrer noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : July"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    href: Minutes100619draft,
    target: "_blank",
    rel: "noreferrer noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : June"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    href: Minutes1305191,
    target: "_blank",
    rel: "noreferrer noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : May"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    href: Minutes1,
    target: "_blank",
    rel: "noreferrer noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : Minutes Annual Parish Meeting 6 May"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    href: Minutes080419,
    target: "_blank",
    rel: "noreferrer noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }, " "), ": April"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    href: Minutes110319DRAFT,
    target: "_blank",
    rel: "noreferrer noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : March"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    href: Mins11February2019DRAFT,
    target: "_blank",
    rel: "noreferrer noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : February"), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement("a", {
    href: Mins14January2019,
    target: "_blank",
    rel: "noreferrer noopener"
  }, React.createElement("i", {
    class: "fas fa-file-pdf"
  }), " : January"), "\n"), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
